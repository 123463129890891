import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=5016f8c3&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"
import style0 from "./Contact.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports